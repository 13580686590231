import React from "react";
import parse from "html-react-parser";

import SectionLayoutGrid from "../../layouts/SectionLayoutGrid";
import CustomTrainingHeader from "./CustomTrainingHeader";
import TrainingCard from "../../cards/TrainingCard";

import {Body, Header2, Title} from "../../styled/typography/Typography";
import Col from "../../styled/grid/Col";
import {OnlyDesktop, OnlyMobile} from "../../styled/views/MobileOrDesktop";

const Training = ({id, training}) => {

    return (
        <SectionLayoutGrid id={id}>
            <Col>
                <Title color="white" marginTop="normal" marginBottom="normal">
                    {training.title}
                </Title>
                <Body color="body-text" marginBottom="small">
                    {parse(training.description)}
                </Body>
                <Body color="body-text">
                    {parse(training.lang === "hu" ? "További információ: <a href=\"mailto:education@balasys.hu\">education@balasys.hu</a>" : "More information: <a href=\"mailto:education@balasys.hu\">education@balasys.hu</a>")}
                </Body>
                <Col>
                    <OnlyDesktop>
                        <CustomTrainingHeader text={training.section_balasys_title}/>
                    </OnlyDesktop>
                    <OnlyMobile>
                        <Header2 small color="white" marginTop="normal">
                            {training.section_one_identity}
                        </Header2>
                    </OnlyMobile>
                </Col>
            </Col>
            <Col>
                <Body color="body-text">
                    {parse(training.section_balasys_description)}
                </Body>
            </Col>
            {training.balasys_cards.map((item, index) => (
                <Col span={4} key={index}>
                    <TrainingCard key={index} title={item.title} list={item.available_trainings}
                                  avatar={item.avatar.url} button={item.button} date={item.date}
                                  lang={training.lang}/>
                </Col>
            ))}
            <Col>
                <OnlyDesktop>
                    <CustomTrainingHeader text={training.section_one_identity}/>
                </OnlyDesktop>
                <OnlyMobile>
                    <Header2 small color="white" marginTop="normal">
                        {training.section_one_identity}
                    </Header2>
                </OnlyMobile>
            </Col>
            <Col>
                <Body color="body-text">
                    {parse(training.section_one_identity_description)}
                </Body>
            </Col>
            {training.one_identity_cards.map((item, index) => (
                <Col span={4} key={index}>
                    <TrainingCard title={item.title} list={item.available_trainings}
                                  avatar={item.avatar.url} button={item.button} lang={training.lang}/>
                </Col>
            ))}
            <Col>
                <Body color="body-text" textAlign="center" small>
                    {parse(training.lang === "hu" ? "A fenti tanfolyamokra vonatkozó egyedi igényekkel kapcsolatban kérjük, hogy keresse kollégánkat az <a href=\"mailto:education@balasys.hu\">education@balasys.hu</a> címen." : "For more information about custom requests regarding the courses mentioned above please contact our colleagues at <a href=\"mailto:education@balasys.hu\">education@balasys.hu</a>.")}
                </Body>
            </Col>
        </SectionLayoutGrid>
    )
}

export default Training;