import styled from "styled-components";
import {Body, BodyLi} from "../typography/Typography";

export const TrainingCardWrapper = styled.div`
  display: grid;
  align-self: stretch;
  align-items: stretch;
  grid-gap: 20px;
  height: auto;
  box-shadow: 16px 16px 48px rgba(0, 0, 0, 0.1);
  transition: box-shadow 500ms;
  align-content: stretch;
  background-color: transparent;
  border: 1px solid rgba(169, 198, 234, 0.5);
  border-radius: 0;
  padding: 20px;
  
  &:hover {
    box-shadow: 0 0 19px 0 #4fa0e2;
  }

  ${props => {
    if (props.date) {
      return `
        grid-template-rows: 200px  125px 100px 50px;
        @media screen and (max-width: 800px) {
          grid-template-rows: 100px  100px 50px 50px;
        }
    `
    } else if (props.nodate) {
      return `
        grid-template-rows: 200px 175px 50px;
        @media screen and (max-width: 800px) {
          grid-template-rows: 100px 125px 50px;
        }
    `
    }
  }}
`

export const TrainingCardHeader = styled.div`
  display: grid;
  background-color: rgba(13, 65, 121, 0.7);
  margin-left: -30px;
  margin-right: -30px;
  padding-left: 40px;
  padding-right: 40px;
`

export const TrainingCardHeaderLogo = styled.img`
  justify-self: end;
  margin-top: 10px;
  opacity: 0.5;
  height: 20px;
`

export const TrainingCardTimeContainer = styled.div`
  background-color: rgba(13, 65, 121, 0.7);
  padding: 15px;
  box-sizing: border-box;
  display: grid;
`

export const TrainingCardTimeTitle = styled(Body)`
  opacity: 0.5;
`

export const TrainingCardTimeDate = styled(Body)`
  align-self: end;
`

export const TrainingCardLi = styled(BodyLi)`
  &:hover {
    opacity: 0.7;
  }
`