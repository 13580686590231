import styled from "styled-components";

export const LifeTimeContainer = styled.div`
  display: grid;
  grid-row-gap: 10px;
  grid-template-columns: 1fr min-content 1fr;
  grid-template-rows: repeat(8, 60px);
  align-items: center;
  grid-column-gap: 20px;
`

export const LifeTimeImg = styled.img`
  z-index: 2;
  width: 25px;
  justify-self: center;
  
  @media screen and(max-width: 800px){
      width: 15px;
      padding-bottom: 20px;
  }
`

export const LifeTimeMiddleLine = styled.img`
  background-color: rgba(13, 65, 121, 0.5);
  width: 1px;
  transform: translateX(-50%);
  position: absolute;
  height: 50%;
  left: 50%;
  z-index: 1;
  
  @media screen and(max-width: 800px){
    height: 55%;
    transform: translateY(-10%);
  }
`