import styled from "styled-components";

export const ServicesHeaderImg = styled.img`
  width: 100%;
  cursor: pointer;
  place-self: center;
  
  @media screen and(max-width: 800px) {
    margin: 0 0 40px 0;
  }
`