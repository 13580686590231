import React from "react";

import Col from "../../styled/grid/Col";
import {
    CustomHeader,
    CustomHeaderBackground,
    CustomHeaderContainer
} from "../../styled/pages/services/training/CustomHeaderComponents";

const CustomTrainingHeader = ({text}) => {
    return (
        <Col marginBottom="small">
            <CustomHeaderContainer>
                <CustomHeader small>
                    {text}
                </CustomHeader>
            </CustomHeaderContainer>
            <CustomHeaderBackground/>
        </Col>
    )
}

export default CustomTrainingHeader