import React from "react";
import {graphql} from "gatsby";

import Layout from "/src/components/layouts/Layout";
import ServicesHeader from "/src/components/pages/services/ServicesHeader";
import Training from "/src/components/pages/services/Training";
import TechnicalSupport from "/src/components/pages/services/TechnicalSupport";

import {LottieAnimation} from "../../components/styled/lotti/LottieAnimations";

import circuit from "/static/assets/animations/circuit/circuit01.json";
import circuit2 from "/static/assets/animations/circuit/circuit02.json";
import circuit3 from "/static/assets/animations/circuit/circuit_right01.json";

const Services = ({data}) => {

    const {service} = data;
    const {training} = data;

    return (
        <Layout lang={service.lang} seo={service.SEO}>
            <LottieAnimation animationData={circuit} left="true"/>
            <ServicesHeader
                id={"consultancy"}
                title={service.consultancy.title}
                description={service.consultancy.description}
                button={service.consultancy.button}
                cards={service.consultancy.cards}
            />
            <ServicesHeader
                id={"implementation"}
                title={service.implementation.title}
                description={service.implementation.description}
                button={service.implementation.button}
                avatar={service.implementation.avatar.url}
            />
            <Training id={"training"} training={training}/>
            <LottieAnimation animationData={circuit3} right="true"/>
            <ServicesHeader
                id={"support"}
                title={service.technical.title}
                description={service.technical.description}
                subtitle={service.technical.subtitle_support}
                description2={service.technical.description_support}
                subtitle2={service.technical.subtitle_contact}
                contact_line1={service.technical.contact_line1}
                contact_line2={service.technical.contact_line2}
                button={service.technical.button}
                isExternal={true}
                cards={service.technical.cards}
                lang={service.lang}
            />
            <TechnicalSupport lang={service.lang}/>
            <LottieAnimation animationData={circuit2} left="true"/>
            <ServicesHeader
                id={"competence"}
                title={service.competence.title}
                description={service.competence.description}
                button={service.competence.button}
                cards={service.competence.cards}
            />
        </Layout>
    )
}

export const query = graphql`
   query GetSingleService($locale: String) {
         service: strapiServices(lang: { eq: $locale }) {
          lang
          consultancy {
            title
            description
            button {
              id
              link
              name
            }
            cards {
              id
              title
              avatar {
                url
              }
            }
          }
          implementation {
            title
            description
            button {
              id
              link
              name
            }
            avatar {
              url
            }
          }
          technical {
            title
            description
            subtitle_support
            description_support
            subtitle_contact
            contact_line1
            contact_line2
            button {
              id
              link
              name
            }
            cards {
              id
              title
              avatar {
                url
              }
            }
          }
          competence {
            title
            description
            button {
              id
              link
              name
            }
            cards {
              id
              title
              avatar {
                url
              }
            }
          }
          SEO {
            title
            isIndexable
            description
            keywords
            preview {
              url
            }
          }
         }
         training: strapiTrainings(lang: { eq: $locale }) {
              lang
              title
              description
              section_balasys_title
              section_balasys_description
              balasys_cards {
                id
                title
                available_trainings {
                  id
                  link
                  name
                }
                date
                button {
                  link
                  name
                }
                avatar {
                  url
                }
              }
              section_one_identity
              section_one_identity_description
              one_identity_cards {
                id
                title
                available_trainings {
                  id
                  link
                  name
                }
                button {
                  id
                  link
                  name
                }
                avatar {
                  url
                }
              }
      }
   }
 `

export default Services