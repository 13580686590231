import React from "react"
import parse from "html-react-parser";
import {Link} from "gatsby";

import SectionLayoutGrid from "../../layouts/SectionLayoutGrid";
import BenefitsCard from "../../cards/BenefitsCard";

import Col from "../../styled/grid/Col";
import {Body, Header2, Title} from "../../styled/typography/Typography";
import {ButtonA, ButtonContainer, ButtonLink} from "../../styled/button/ButtonComponents";
import {ServicesHeaderImg} from "../../styled/pages/services/header/ServicesHeaderComponents";
import {OnlyDesktop, OnlyMobile} from "../../styled/views/MobileOrDesktop";

const ServicesHeader = ({
                            id,
                            title,
                            description,
                            subtitle,
                            description2,
                            subtitle2,
                            contact_line1,
                            contact_line2,
                            button,
                            isExternal,
                            cards,
                            avatar,
                            lang
                        }) => {

    return (
        <SectionLayoutGrid id={id}>
            <Col span={6} grid center>
                <Title color="white" marginBottom="normal">
                    {title}
                </Title>
                {avatar &&
                <OnlyMobile>
                    <ServicesHeaderImg alt={title} src={avatar} className="image-for-modal"/>
                </OnlyMobile>
                }
                {cards && !avatar &&
                <OnlyMobile>
                    {cards.map((item) => (
                        <BenefitsCard key={item.id} title={item.title} avatar={item.avatar.url}/>))}
                </OnlyMobile>
                }
                {description &&
                <Body color="body-text" marginBottom="small">
                    {parse(description)}
                </Body>
                }
                {(subtitle2 && lang === "hu") &&
                <Body color="body-text" marginBottom="small" italic>
                    Az ügykezelési folyamatról <Link to={"/hu/services/support-level"}>további információt ITT
                    talál.</Link>
                </Body>
                }
                {(subtitle2 && lang === "en") &&
                <Body color="body-text" marginBottom="small" italic>
                    More information about the case management process <Link to={"/en/services/support-level"}>can be
                    found HERE.</Link>
                </Body>
                }
                {subtitle &&
                <Header2 small color="one-identity-primary" marginBottom="small">
                    {subtitle}
                </Header2>}
                {description2 &&
                <Body color="body-text" marginBottom="small">
                    {parse(description2)}
                </Body>
                }
                {subtitle2 &&
                <Header2 small color="white" marginBottom="small">
                    {subtitle2}
                </Header2>
                }
                {contact_line1 &&
                <Body color="body-text">
                    {contact_line1}
                </Body>
                }
                {contact_line2 &&
                <Body color="body-text">
                    {contact_line2}
                </Body>
                }
                <ButtonContainer>
                    {button && !isExternal &&
                    <ButtonLink to={button[0].link} color="primary-brand">
                        {button[0].name}
                    </ButtonLink>
                    }
                    {button && isExternal &&
                    <ButtonA href={button[0].link} color="secondary-brand" target="_blank" rel="noreferrer">
                        {button[0].name}
                    </ButtonA>
                    }
                </ButtonContainer>
            </Col>
            {avatar &&
            <Col span={6} center>
                <OnlyDesktop>
                    <ServicesHeaderImg alt={title} src={avatar} className="image-for-modal"/>
                </OnlyDesktop>
            </Col>
            }
            {cards && !avatar &&
            <Col span={6} center grid>
                <OnlyDesktop>
                    {cards.map((item) => (
                        <BenefitsCard key={item.id} title={item.title} avatar={item.avatar.url}/>))}
                </OnlyDesktop>
            </Col>
            }
        </SectionLayoutGrid>
    )
}

export default ServicesHeader