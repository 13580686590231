import React from "react";

import Col from "../../styled/grid/Col";
import {LifeTimeContainer, LifeTimeImg, LifeTimeMiddleLine} from "../../styled/pages/services/life-time/LifeTimeComponents";
import {Body} from "../../styled/typography/Typography";

import dotImg from "../../../../static/assets/point_icon.svg";

const LifeTime = ({lang}) => {

    return (
        <Col>
            <LifeTimeMiddleLine/>
            <LifeTimeContainer>
                <Body color="body-text" textAlign="right">
                    Zorp Gateway 6
                </Body>
                <LifeTimeImg alt="pötty1" src={dotImg}/>
                <Body color="body-text" textAlign="left">
                    2020.10.31.
                </Body>
                <Body color="body-text" textAlign="right">
                    Zorp Gateway 7
                </Body>
                <LifeTimeImg alt="pötty2" src={dotImg}/>
                <Body color="body-text" textAlign="left">
                    {lang === "hu" ? "A következő major verzió kiadási dátuma + 1 év" : "Release date of next major version plus 1 year"}
                </Body>
                <Body color="body-text" textAlign="right">
                    Proxedo API Security 3
                </Body>
                <LifeTimeImg alt="pötty3" src={dotImg}/>
                <Body color="body-text" textAlign="left">
                    {lang === "hu" ? "A következő major verzió kiadási dátuma + 1 év" : "Release date of next major version plus 1 year"}
                </Body>
                <Body color="body-text" textAlign="right">
                    One Identity Safeguard for Privileged Passwords
                </Body>
                <LifeTimeImg alt="pötty4" src={dotImg}/>
                <Body color="body-text" textAlign="left">
                    <a href="https://support.oneidentity.com/one-identity-safeguard-for-privileged-passwords" target="_blank" rel="noreferrer">
                        link
                    </a>
                    {lang === "hu" ? " - a „Product life cycle and policies” fülön" : " - learn more on ‘Product life cycle and policies’ option"}
                </Body>
                <Body color="body-text" textAlign="right">
                    One Identity Safeguard for Privileged Sessions
                </Body>
                <LifeTimeImg alt="pötty5" src={dotImg}/>
                <Body color="body-text" textAlign="left">
                    <a href="https://support.oneidentity.com/one-identity-safeguard-for-privileged-sessions" target="_blank" rel="noreferrer">
                        link
                    </a>
                    {lang === "hu" ? " - a „Product life cycle and policies” fülön" : "- learn more on ‘Product life cycle and policies’ option"}
                </Body>
                <Body color="body-text" textAlign="right">
                    syslog-ng Premium Edition
                </Body>
                <LifeTimeImg alt="pötty6" src={dotImg}/>
                <Body color="body-text" textAlign="left">
                    <a href="https://support.oneidentity.com/syslog-ng-premium-edition" target="_blank" rel="noreferrer">
                        link
                    </a>
                    {lang === "hu" ? " - a „Product life cycle and policies” fülön" : "- learn more on ‘Product life cycle and policies’ option"}
                </Body>
                <Body color="body-text" textAlign="right">
                    syslog-ng Store Box
                </Body>
                <LifeTimeImg alt="pötty7" src={dotImg}/>
                <Body color="body-text" textAlign="left">
                    <a href="https://support.oneidentity.com/syslog-ng-store-box" target="_blank" rel="noreferrer">
                        link
                    </a>
                    {lang === "hu" ? " - a „Product life cycle and policies” fülön" : "- learn more on ‘Product life cycle and policies’ option"}
                </Body>
            </LifeTimeContainer>
            <Col>
                <Body color="body-text" small textAlign="center" marginTop="large">
                    {lang === "hu" ? "Megjegyzés: Csak az adott verzió legújabb revíziója támogatott. Erősen ajánlott frissíteni a legújabb revízióra." : "Note: Only the latest revision of a release is supported. Upgrading to the latest revision is highly recommended."}
                </Body>
            </Col>
        </Col>
    )
}

export default LifeTime