import React from "react";
import {Link} from "gatsby";

import {
    TrainingCardHeader,
    TrainingCardHeaderLogo, TrainingCardLi,
    TrainingCardTimeContainer, TrainingCardTimeDate,
    TrainingCardTimeTitle, TrainingCardWrapper
} from "../styled/cards/TrainingCardComponents";
import {Body, Header3} from "../styled/typography/Typography";
import {ButtonContainer, ButtonLink} from "../styled/button/ButtonComponents";
import Col from "../styled/grid/Col";

const TrainingCard = ({avatar, title, list, date, button, lang}) => {
    return (
        <TrainingCardWrapper nodate={!date} date={date}>
            <TrainingCardHeader>
                <TrainingCardHeaderLogo src={avatar} alt={title}/>
                <Header3 color="white" upper>
                    {title}
                </Header3>
            </TrainingCardHeader>
            <div>
                <Body color="body-text" upper bold small>
                    {lang === "hu" ? "További információ:" : "More information:"}
                </Body>
                <ul>
                    {list.map((item) => (
                        <Link key={item.id} to={item.link}>
                            <TrainingCardLi small color="primary-brand" bold
                                            marginTop="smaller">{item.name}</TrainingCardLi>
                        </Link>
                    ))}
                </ul>
            </div>
            {date && (
                <TrainingCardTimeContainer>
                    <TrainingCardTimeTitle color="white" small>
                        {lang === "hu" ? "A KÖVETKEZŐ TANFOLYAM IDŐPONTJAI:" : "NEXT AVAILABLE COURSES:"}
                    </TrainingCardTimeTitle>
                    <TrainingCardTimeDate small color="body-text" textAlign="center">
                        {date}
                    </TrainingCardTimeDate>
                </TrainingCardTimeContainer>
            )}
            <Col grid center>
                <ButtonContainer>
                    <ButtonLink to={button.link} color="primary-brand">
                        {button.name}
                    </ButtonLink>
                </ButtonContainer>
            </Col>
        </TrainingCardWrapper>
    );
};

export default TrainingCard;
