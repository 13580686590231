import React from "react";

import SectionLayoutGrid from "../../layouts/SectionLayoutGrid";
import CustomTrainingHeader from "./CustomTrainingHeader";
import LifeTime from "./LifeTime";

const TechnicalSupport = ({lang}) => {

    return (
        <SectionLayoutGrid>
            <CustomTrainingHeader text={lang === "hu" ? "Termék életciklusok" : "Product Life Cycles"}/>
            <LifeTime lang={lang}/>
        </SectionLayoutGrid>
    )
}

export default TechnicalSupport